import React, { useState } from 'react'
import { Layout } from '@/components/layouts/Layout'
import { Link } from '@/components/elements/Link'
import { useForm, Controller } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { setContactCompensation } from '@/store/contactCompensation/action'
import { ContactCompensation } from '@/store/contactCompensation/types'
import { RootState } from '@/store/index'
import { ErrorMessage } from '@hookform/error-message'
import { theme } from '@/styles/theme'
import { navigate } from 'gatsby'
import useFormInput from '@/hooks/useFormInput'
import ReactDatePicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import ja from 'date-fns/locale/ja'
import { STATUS } from '@/constants/status'
import { Seo } from '@/components/Seo'
import { BreadCrumbs } from '@/components/layouts/Breadcrumbs'
import { Title } from '@/components/elements/Title'
import {
  FormItemAlert,
  FormAction,
  FormMain,
  FormDatePicker,
  FormRadio,
  FormSubItem,
  FormSubItemInner,
  Caution,
  FormHeader,
  FormContent,
  FormCheckbox,
  FormSubGroup,
  FormInputStyle,
} from '@/styles/form'
import { Button } from '@/components/elements/Button'
import { MainWrapper, TitleWrapper } from '@/styles/common'
import { useFileValidation } from '@/hooks/useFileValidation'
import { FormFileUpload } from '@/components/layouts/Form/FormFileUpload'
import { FormFooter } from '@/components/layouts/Form/FormFooter'
import { FormLabel } from '@/components/layouts/Form/FormLabel'
import { FormSubLabel } from '@/components/layouts/Form/FormSubLabel'
import { FormDateSelect } from '@/components/layouts/Form/FormDateSelect'
import { FormInput } from '@/components/layouts/Form/FormInput'

export default function compensation() {
  const breadCrumbs: {
    name: string
    path?: string
  }[] = [
    {
      name: 'トップ',
      path: '/',
    },
    {
      name: 'お問い合わせ',
      path: '/contact/',
    },
    {
      name: '補償申請フォーム',
    },
  ]
  const dispatch = useDispatch()

  const form = useSelector((state: RootState) => state.contactCompensation)
  const [detail, setDetail] = useState<string>(form.detail ?? '')

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    control,
    trigger,
  } = useForm<ContactCompensation>()

  const {
    sliceMaxLength,
    formRadioClick,
    formCheckboxBtnClick,
    formCheckboxClick,
    autoResizeTextArea,
  } = useFormInput()

  const textAreaRef = autoResizeTextArea(detail)
  const { ref } = register('detail')

  const fileValidation = useFileValidation<ContactCompensation>(
    form,
    setValue,
    errors,
    clearErrors
  )
  const { files, otherFiles, fileErrors, setFileErrors } = fileValidation

  const [useSupayStatus, setUseSupayStatus] = useState<string>(
    form.is_use_supay
  )

  registerLocale('ja', ja)
  const today = new Date()
  const minDate = new Date()
  minDate.setDate(today.getDate() - 60)

  const onSubmit = (data: ContactCompensation) => {
    data.require_files = [...files]
    data.other_files = [...otherFiles]
    dispatch(setContactCompensation(data))
    navigate('/contact/compensation/confirm/')
  }

  return (
    <Layout isForm>
      <Seo
        title="補償申請 - お問い合わせ"
        canonical="https://su-pay.jp/contact/compensation/"
        description="不正利用による補償を希望される場合は、こちらから申請をお願いします。"
      />
      <Title as="h1" bg={true}>
        お問い合わせ
      </Title>
      <MainWrapper>
        <BreadCrumbs breadCrumbs={breadCrumbs} />
        <TitleWrapper>
          <Title as="h2" color={theme.colors.supayBlue}>
            補償申請フォーム
          </Title>
        </TitleWrapper>
        <FormHeader color="blue">
          <Caution>
            <div>
              <p>
                お客様のご利用ではないSU-PAYでの支払いが発生した場合に、「
                <Link
                  link="/terms/#補償制度"
                  text="トライアル電子マネーモバイル会員規約 第15条の2（補償）"
                  isExternal
                />
                」に基づき、補償申請を行うことができます。
              </p>
              <p>
                お手数おかけしますが、以下をご確認のうえ、必要情報を入力してください。
              </p>
            </div>
            <p>＜補償対象条件＞</p>
            <ul>
              <li>損害発生日から30日以内の申請であること</li>
              <li>
                初回の申請であること、または前回申請した日から1年を超えていること
              </li>
              <li>ご家族や同居人などの利用ではないこと</li>
              <li>
                警察へ被害の届出を行うこと
                <span className="emphasize-red">
                  （ご自身で相談した警察署・担当者名、受理番号等をお控えください。申請時に必要です。）
                </span>
              </li>
              <li>弊社所定の審査条件を満たしていること</li>
            </ul>
            <div>
              <p>※</p>
              <p>
                迷惑メール防止のためにドメイン指定受信をしている方は、あらかじめ設定を解除するか「@su-pay.jp」と「@trial-net.co.jp」の受信設定をお願いします。
              </p>
            </div>
          </Caution>
        </FormHeader>
        <form
          method="post"
          encType="multipart/form-data"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
        >
          <FormMain>
            <FormLabel isRequired>利用している電話番号</FormLabel>
            <FormContent>
              <FormInput
                defaultValue={form.tel}
                type="tel"
                id="tel"
                placeholder="0123456789"
                maxLength={15}
                register={register('tel', {
                  required: {
                    value: true,
                    message: '入力してください。',
                  },
                  pattern: {
                    message: '電話番号を正しい形式で入力してください',
                    value: /^[0-9]+$/,
                  },
                })}
                errorMessage={errors.tel}
                hintText={['ハイフンなし']}
              />
            </FormContent>
            <FormLabel isRequired>返信先メールアドレス</FormLabel>
            <FormContent>
              <FormInput
                type="email"
                id="email"
                placeholder="youremail@example.com"
                defaultValue={form.email}
                maxLength={255}
                register={register('email', {
                  required: {
                    value: true,
                    message: '入力してください。',
                  },
                  pattern: {
                    message: 'メールアドレスを正しい形式で入力してください',
                    value:
                      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  },
                })}
                errorMessage={errors.email}
              />
            </FormContent>
            <FormLabel isRequired>被害に気づかれた時期</FormLabel>
            <FormContent>
              <FormDatePicker>
                <Controller
                  control={control}
                  name="time_of_notice"
                  defaultValue={form.time_of_notice}
                  rules={{
                    required: {
                      value: true,
                      message: '入力してください。',
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <ReactDatePicker
                      onChange={onChange}
                      showTimeInput
                      timeInputLabel="時刻:"
                      selected={value}
                      locale="ja"
                      dateFormat="yyyy/MM/dd HH:mm"
                      maxDate={today}
                      minDate={minDate}
                      dateFormatCalendar={'yyyy年 MM月'}
                      className="date-picker"
                      calendarStartDay={1}
                    />
                  )}
                />
              </FormDatePicker>
              <FormDateSelect<ContactCompensation>
                fieldName="time_of_notice"
                setValue={setValue}
                isRequiredTime
                defaultValue={form.time_of_notice}
              />
              <ErrorMessage
                errors={errors}
                name="time_of_notice"
                render={({ message }) => (
                  <FormItemAlert>{message}</FormItemAlert>
                )}
              />
            </FormContent>
            <FormLabel isRequired>被害発生時期</FormLabel>
            <FormContent>
              <FormDatePicker>
                <Controller
                  control={control}
                  name="time_of_emergence"
                  defaultValue={form.time_of_emergence}
                  rules={{
                    required: {
                      value: true,
                      message: '入力してください。',
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <ReactDatePicker
                      onChange={onChange}
                      showTimeInput
                      timeInputLabel="時刻:"
                      selected={value}
                      locale="ja"
                      dateFormat="yyyy/MM/dd HH:mm"
                      maxDate={today}
                      minDate={minDate}
                      dateFormatCalendar={'yyyy年 MM月'}
                      className="date-picker"
                      calendarStartDay={1}
                    />
                  )}
                />
              </FormDatePicker>
              <FormDateSelect<ContactCompensation>
                fieldName="time_of_emergence"
                setValue={setValue}
                isRequiredTime
                defaultValue={form.time_of_emergence}
              />
              <ErrorMessage
                errors={errors}
                name="time_of_emergence"
                render={({ message }) => (
                  <FormItemAlert>{message}</FormItemAlert>
                )}
              />
            </FormContent>
            <FormLabel isRequired>相談した警察署・担当者名</FormLabel>
            <FormContent>
              <FormInput
                type="text"
                id="police"
                defaultValue={form.police}
                maxLength={255}
                register={register('police', {
                  required: {
                    value: true,
                    message: '入力してください。',
                  },
                })}
                errorMessage={errors.police}
              />
            </FormContent>
            <FormLabel isRequired>警察への被害届の受理番号</FormLabel>
            <FormContent>
              <FormInput
                type="text"
                id="receipt-number"
                defaultValue={form.receipt_number}
                onInput={(e) => sliceMaxLength(e, 255)}
                register={register('receipt_number', {
                  required: {
                    value: true,
                    message: '入力してください。',
                  },
                })}
                errorMessage={errors.receipt_number}
              />
            </FormContent>
            <FormLabel isRequired>SU-PAYを利用しているか</FormLabel>
            <FormContent>
              <FormSubGroup>
                <FormSubItem>
                  <FormSubItemInner>
                    <FormRadio onClick={formRadioClick}>
                      <input
                        defaultChecked={form.is_use_supay === STATUS.YES}
                        type="radio"
                        id="use-yes"
                        value={STATUS.YES}
                        {...register('is_use_supay', {
                          required: {
                            value: true,
                            message: '入力してください。',
                          },
                        })}
                        onChange={(e) => {
                          setUseSupayStatus(e.target.value)
                          setValue('is_use_supay', e.target.value)
                          trigger('is_use_supay')
                        }}
                      />
                      <label htmlFor="use-yes">はい</label>
                    </FormRadio>
                    <FormRadio onClick={formRadioClick}>
                      <input
                        defaultChecked={form.is_use_supay === STATUS.NO}
                        type="radio"
                        id="use-no"
                        value={STATUS.NO}
                        {...register('is_use_supay')}
                        onChange={(e) => {
                          setUseSupayStatus(e.target.value)
                          setValue('is_use_supay', e.target.value)
                          trigger('is_use_supay')
                        }}
                      />
                      <label htmlFor="use-no">いいえ</label>
                    </FormRadio>
                  </FormSubItemInner>
                  <ErrorMessage
                    errors={errors}
                    name="is_use_supay"
                    render={({ message }) => (
                      <FormItemAlert>{message}</FormItemAlert>
                    )}
                  />
                </FormSubItem>
                {useSupayStatus === STATUS.YES && (
                  <>
                    <FormSubItem>
                      <FormSubLabel>被害が発生しているSU-PAY残高</FormSubLabel>
                      <FormInput
                        type="number"
                        id="balance"
                        defaultValue={form.balance}
                        onInput={(e) => sliceMaxLength(e, 10)}
                        register={register('balance', {
                          pattern: {
                            value: /^[0-9]+$/,
                            message: '半角数字で入力してください',
                          },
                        })}
                        hintText={[
                          '半角数字',
                          '分かる場合のみご入力ください。',
                        ]}
                      />
                    </FormSubItem>
                    <FormSubItem>
                      <FormSubLabel>被害が発生している銀行名</FormSubLabel>
                      <FormInput
                        type="text"
                        id="bank"
                        defaultValue={form.bank}
                        maxLength={255}
                        register={register('bank')}
                        hintText={[
                          '銀行チャージをご利用の場合のみご入力ください。',
                        ]}
                      />
                    </FormSubItem>
                  </>
                )}
              </FormSubGroup>
            </FormContent>
            <FormLabel isRequired>対象被害総額</FormLabel>
            <FormContent>
              <FormInput
                type="number"
                id="total-damage"
                defaultValue={form.total_damage}
                onInput={(e) => sliceMaxLength(e, 10)}
                register={register('total_damage', {
                  required: {
                    value: true,
                    message: '入力してください。',
                  },
                  pattern: {
                    value: /^[0-9]+$/,
                    message: '半角数字で入力してください',
                  },
                })}
                errorMessage={errors.total_damage}
                hintText={['半角数字']}
              />
            </FormContent>
            <FormLabel isRequired>
              当社以外から今回の被害に関する補償を受けたか、または受ける予定があるか
            </FormLabel>
            <FormContent>
              <FormSubGroup>
                <FormSubItem>
                  <FormSubItemInner>
                    <FormRadio onClick={formRadioClick}>
                      <input
                        defaultChecked={form.has_compensation === STATUS.YES}
                        type="radio"
                        id="compensation-yes"
                        value={STATUS.YES}
                        {...register('has_compensation', {
                          required: {
                            value: true,
                            message: '入力してください。',
                          },
                        })}
                        onChange={(e) => {
                          setValue('has_compensation', e.target.value)
                          trigger('has_compensation')
                        }}
                      />
                      <label htmlFor="compensation-yes">はい</label>
                    </FormRadio>
                    <FormRadio onClick={formRadioClick}>
                      <input
                        defaultChecked={form.has_compensation === STATUS.NO}
                        type="radio"
                        id="compensation-no"
                        value={STATUS.NO}
                        {...register('has_compensation')}
                        onChange={(e) => {
                          setValue('has_compensation', e.target.value)
                          trigger('has_compensation')
                        }}
                      />
                      <label htmlFor="compensation-no">いいえ</label>
                    </FormRadio>
                  </FormSubItemInner>
                  <ErrorMessage
                    errors={errors}
                    name="has_compensation"
                    render={({ message }) => (
                      <FormItemAlert>{message}</FormItemAlert>
                    )}
                  />
                </FormSubItem>
              </FormSubGroup>
            </FormContent>
            <FormLabel isRequired>
              <Link
                link="/terms/#補償制度"
                text="SU-PAY補償制度に関する規約"
                isExternal={true}
              />
              を確認しました
              <br />
              <span>
                ※トライアル電子マネーモバイル会員規約 第15条の2（補償）
              </span>
            </FormLabel>
            <FormContent>
              <FormCheckbox onClick={formCheckboxBtnClick}>
                <label onClick={(e) => e.stopPropagation()} htmlFor="terms-yes">
                  <input
                    type="checkbox"
                    defaultChecked={form.is_terms_accept}
                    id="terms-yes"
                    {...register('is_terms_accept', {
                      required: {
                        value: true,
                        message: '入力してください。',
                      },
                    })}
                    onChange={(e) => {
                      register('is_terms_accept').onChange(e)
                    }}
                    onClick={formCheckboxClick}
                  />
                  <span>はい</span>
                </label>
              </FormCheckbox>
              <ErrorMessage
                errors={errors}
                name="is_terms_accept"
                render={({ message }) => (
                  <FormItemAlert>{message}</FormItemAlert>
                )}
              />
            </FormContent>
            <FormLabel>
              申請内容の詳細（お気づきの点がありましたら入力してください）
            </FormLabel>
            <FormContent>
              <FormInputStyle
                as="textarea"
                id="detail"
                maxLength={1000}
                {...register('detail', {
                  onChange: (e) => setDetail(e.target.value),
                })}
                defaultValue={form.detail}
                ref={(e: HTMLTextAreaElement | null) => {
                  ref(e)
                  textAreaRef.current = e
                }}
              />
            </FormContent>
            <FormFileUpload
              registerRequire={register('require_files', {
                required: {
                  value: true,
                  message: '選択してください。',
                },
              })}
              errorMessageRequire={errors.require_files}
              fileValidation={fileValidation}
              isRequiredOtherFile
            />
          </FormMain>
          <FormFooter />
          <FormAction>
            <Button
              showArrowRight
              type="submit"
              onClick={() => {
                fileErrors.length > 0 && setFileErrors([])
              }}
            >
              確認画面へ
            </Button>
          </FormAction>
        </form>
      </MainWrapper>
    </Layout>
  )
}
