import { ActionTypes } from '@/store/actionTypes'
import {
  ContactCompensation,
  ContactCompensationActionTypes,
} from '@/store/contactCompensation/types'

export const setContactCompensation = (
  payload: ContactCompensation
): ContactCompensationActionTypes => {
  return {
    type: ActionTypes.contactCompensation,
    payload,
  }
}

export const clearState = () => {
  return {
    type: ActionTypes.clearState,
  }
}
